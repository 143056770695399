window.angular.module('MyHippoProducer.Components').directive('selectDropdown', function () {
    return {
        restrict: 'E',
        scope: { ngModel: '=', options: '<', 'formatType': '@', ngDisabled: '<', label: '<' },
        templateUrl: 'components/select-dropdown/select-dropdown.html',
        controller: function ($scope, $filter) {
            $scope.formatOption = (val) => {
                if ($scope.formatType === 'dollar' && !isNaN(val)) {
                    return $filter('currency')(val, '$', 0);
                } else if (!!val && $scope.formatType === 'uppercase') {
                    return val.toUpperCase();
                }
                return val;
            };
        }
    };
});
